import React from 'react';
import ReactLoading from 'react-loading';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

//Import CSS & Styles
import './scss/app.scss';

//Import Layouts & Pages
const Sidebar = React.lazy(() => import('./views/containers/Sidebar'));

const Login = React.lazy(() => import('./views/pages/Login.js'));
const Register = React.lazy(() => import('./views/pages/Register.js'));

const Servers = React.lazy(() => import('./views/pages/Servers'));
const CreateServer = React.lazy(() => import('./views/pages/CreateServer'));
const ServerSettings = React.lazy(() => import('./views/pages/ServerSettings'));

const Index = React.lazy(() => import('./views/pages/Index'));

const EventFeed = React.lazy(() => import('./views/pages/EventFeed'));

const Players = React.lazy(() => import('./views/pages/Players'));

const Bases = React.lazy(() => import('./views/pages/Bases'));
const CreateBase = React.lazy(() => import('./views/pages/CreateBase'));
const ManageBase = React.lazy(() => import('./views/pages/ManageBase'));

const Safezones = React.lazy(() => import('./views/pages/Safezones'));
const CreateSafezone = React.lazy(() => import('./views/pages/CreateSafezone'));
const ManageSafezone = React.lazy(() => import('./views/pages/ManageSafezone'));

const Factions = React.lazy(() => import('./views/pages/Factions'));
const ManageFaction = React.lazy(() => import('./views/pages/ManageFaction'));

const RestartManager = React.lazy(() => import('./views/pages/RestartManager'));
const Currency = React.lazy(() => import('./views/pages/Currency'));
const ItemStore = React.lazy(() => import("./views/pages/ItemStore.js"));

const Permissions = React.lazy(() => import('./views/pages/Permissions'));

const Bountys = React.lazy(() => import('./views/pages/Bountys'));

const AutoPosts = React.lazy(() => import('./views/pages/AutoPosts'));

const WhitelistApplication = React.lazy(() => import('./views/pages/Applications'));

const Billing = React.lazy(() => import('./views/pages/Billing'));

class Loading extends React.Component {
  render() {
    return <ReactLoading className="preloader-page" type={'spin'} color={'#FF4655'} height={'2.5%'} width={'2.5%'} />
  }
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { loaded: false, sideBarContent: <Sidebar />, url: window.location.href };
  }

  async componentDidUpdate() {
    if (window.location.href === this.state.url) return;
    this.setState({ url: window.location.href });
    let sideBarContent = <Sidebar showPremiumTabs={false} serverSelected={false} />;
    let authUrls = ['/login', '/register'];
    if (authUrls.includes(window.location.pathname)) sideBarContent = <span></span>;
    const json = await fetch(`${api_url}/user/session`, { credentials: 'include' }).then(r => r.json());

    if (json.code === 403 && !authUrls.includes(window.location.pathname)) return window.location.pathname = '/login';
    if (json.code === 403) return this.setState({ loaded: true, sideBarContent: sideBarContent });
    window.globalThis.user = json.user;
    if (window.location.pathname == '/login') return window.location.pathname = '/servers';
    sideBarContent = <Sidebar showPremiumTabs={json.user.premium} serverSelected={false} />;

    if ((json.user.nitrado.linked === false || json.user.nitrado.username === 'none' || json.user.discord.linked === false || json.user.discord.text === 'none') && window.location.pathname !== '/register') return window.location.pathname = '/register';
    if (json.user.nitrado.linked === false || json.user.discord.linked == false) return this.setState({ loaded: true, sideBarContent: sideBarContent });

    if (json.code === 402 && !['/servers', '/servers/create'].includes(window.location.pathname)) return window.location.pathname = '/servers';
    if (json.code === 402) return this.setState({ loaded: true, sideBarContent: sideBarContent });

    window.globalThis.server_id = json.server.id;
    window.globalThis.server = json.server;

    sideBarContent = <Sidebar showPremiumTabs={json.user.premium} serverSelected={true} />;

    this.setState({ loaded: true, sideBarContent: sideBarContent })
  }

  async componentDidMount() {
    let sideBarContent = <Sidebar showPremiumTabs={false} serverSelected={false} />;
    let authUrls = ['/login', '/register'];
    if (authUrls.includes(window.location.pathname)) sideBarContent = <span></span>;
    const json = await fetch(`${api_url}/user/session`, { credentials: 'include' }).then(r => r.json());

    if (json.code === 403 && !authUrls.includes(window.location.pathname)) return window.location.pathname = '/login';
    if (json.code === 403) return this.setState({ loaded: true, sideBarContent: sideBarContent });
    window.globalThis.user = json.user;
    if (window.location.pathname == '/login') return window.location.pathname = '/servers';
    sideBarContent = <Sidebar showPremiumTabs={json.user.premium} serverSelected={false} />;

    if ((json.user.nitrado.linked === false || json.user.nitrado.username === 'none' || json.user.discord.linked === false || json.user.discord.text === 'none') && window.location.pathname !== '/register') return window.location.pathname = '/register';
    if (json.user.nitrado.linked === false || json.user.discord.linked == false) return this.setState({ loaded: true, sideBarContent: sideBarContent });

    if (json.code === 402 && !['/servers', '/servers/create'].includes(window.location.pathname)) return window.location.pathname = '/servers';
    if (json.code === 402) return this.setState({ loaded: true, sideBarContent: sideBarContent });

    window.globalThis.server_id = json.server.id;
    window.globalThis.server = json.server;

    sideBarContent = <Sidebar showPremiumTabs={json.user.premium} serverSelected={true} />;

    this.setState({ loaded: true, sideBarContent: sideBarContent })
  }
  render() {
    const { loaded, sideBarContent } = this.state;

    return loaded ? (
      <BrowserRouter>
        <div className='kf-normal-layout'>
          <React.Suspense fallback={<Loading />}>
            {sideBarContent}
            <div className="kf-normal">
              <React.Suspense fallback={<Loading />}>
                <Routes>
                  <Route path="/login" exact name="Login" element={<Login />} />
                  <Route path="/register" exact name="Register" element={<Register />} />

                  <Route path="/servers" exact name="Register" element={<Servers />} />
                  <Route path="/servers/create" exact name="Register" element={<CreateServer />} />
                  <Route path="/servers/settings/:server" exact name="Server Settings" element={<ServerSettings />} />

                  <Route path="/home" exact name="Index" element={<Index />} />
                  <Route path="/eventfeed" exact name="Event Feed" element={<EventFeed />} />
                  <Route path="/players" exact name="Players" element={<Players />} />
                  <Route path="/players/:player_id" exat name="Players" element={<Players />} />

                  <Route path="/bases" exact name="Bases" element={<Bases />} />
                  <Route path="/bases/create" exact name="Create Base" element={<CreateBase />} />
                  <Route path="/bases/manage/:base" name="Manage Base " element={<ManageBase />} />

                  <Route path="/safezones" exact name="Safezones" element={<Safezones />} />
                  <Route path="/safezones/create" exact name="Create Safezone" element={<CreateSafezone />} />
                  <Route path="/safezones/manage/:safezone" exact name="Manage Safezone" element={<ManageSafezone />} />

                  <Route path="/factions" exaxt name="Factions" element={<Factions />} />
                  <Route path="/factions/manage/:faction" exaxt name="Manage Faction" element={<ManageFaction />} />

                  <Route path="/restartmanager" exaxt name="Restart Manager" element={<RestartManager />} />

                  <Route path="/currency" exaxt name="Currency" element={<Currency />} />

                  <Route path="/itemstore" exact name="Item Store" element={<ItemStore />} />

                  <Route path="/permissions" exact name="Permissions" element={<Permissions />} />

                  <Route path="/bountys" exact name="Bountys" element={<Bountys />} />

                  <Route path="/whitelistapplication" exact name="Whitelist Application" element={<WhitelistApplication />} />

                  <Route path="/autoposts" exact name="Auto Posts" element={<AutoPosts />} />

                  <Route path="/account/billing" exaxt name="Billing" element={<Billing />} />
                  {/* <Route path="/account/settings" exaxt name="Settings" element={<AccountSettings />} /> */}
                </Routes>
              </React.Suspense>
            </div>
          </React.Suspense>
        </div>
      </BrowserRouter>
    ) : <Loading />;
  }
}
export default App;

const api_url = 'https://api.killfeed.dev';
const recaptcha_site_key = '6LcxeiMdAAAAAOt4GS9X6ruxpipY3R5rTGygEPzi';
export { Loading, api_url, recaptcha_site_key };
